@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

* {
  font-family: 'Outfit', sans-serif;
}

.navbar {
  padding: 0.5rem 1rem;
  min-height: 80px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

body {
  padding-top: 80px;
}

.custom-nav-link {
  color: #db7c26 !important;
}

.custom-nav-link:hover {
  color: #b5661f !important;
}

.brand {
  color: #00509d !important;
  font-size: 22px;
  font-weight: 600;
}

.nav-links {
  font-size: 18px;
  white-space: nowrap;
}

.nav-icon {
  font-size: 18px;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
}

.call-text {
  font-size: 14px;
  color: #ff0000;
}

.phone-link {
  display: flex;
  align-items: center;
  color: #00509d !important;
  padding: 0.25rem 0;
}

.call-icon {
  font-size: 20px;
  margin-right: 0.5rem;
  border: 1px solid #00509d;
  border-radius: 50%;
  padding: 4px;
}

.phone-number {
  font-weight: 600;
}

@media (min-width: 992px) {
  .nav-links {
    margin-left: 80px;
  }
}

@media (max-width: 991px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-collapse {
    width: 100%;
  }

  .navbar-nav {
    width: 100%;
  }

  .nav-item {
    width: 100%;
    padding: 0.5rem 0;
    border-bottom: 1px solid #f0f0f0;
  }

  .nav-item:last-child {
    border-bottom: none;
  }

  .contact-info {
    width: 100%;
    align-items: center;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #f0f0f0;
  }

  .phone-number {
    margin-top: 0.5rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .navbar-brand {
    margin-bottom: 1rem;
  }

  .navbar-toggler {
    margin-bottom: 1rem;
  }
}
