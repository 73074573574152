@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');
*{
  font-family: 'Outfit', sans-serif !important;
}
body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: 'Outfit', sans-serif;
}
header {
  position: relative;
}
.header-img {
  height: 550px;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}
@media (max-width: 992px){
  .header-img{
    height: 50vh;
  }
  #heading2{
    display: none;
  }
  .form-container {
    top: 55%; /* Adjust for large screens */
  }
  #destinations-section{
    display: none;
  }
  #cards-section{
    display: none;
  }
  #flight-offer{
    display: none;
  }
  #srch-hdr{
    margin-bottom: 10px;
  }
}
@media (max-width: 768px){
  .header-img{
    height: 50vh;
  }
  #heading2{
    display: none;
  }
  .form-container {
    top: 60%; /* Adjust for medium screens */
  }
  #destinations-section{
    display: none;
  }
  #flight-offer{
    display: none;
  }
  #srch-hdr{
    margin-bottom: 10px;
  }
}
@media (max-width: 576px){
  .header-img{
    height: 60vh;
  }
  #heading2{
    display: none;
  }
  .form-container {
    top: 65%; /* Adjust for small screens (phones) */
  }
  #destinations-section{
    display: none;
  }
  #flight-offer{
    display: none;
  }
  #srch-hdr{
    margin-bottom: 10px;
  }
}
@media (max-width: 480px){
  .header-img{
    height: 90vh;
  }
  #heading2{
    display: none;
  }
  #srch-hdr{
    margin-bottom: 10px;
  }
}
header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}
.form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 90%;
  max-width: 1200px;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-top: 120px;
}
#psgs{
  display: block;
  font-size: 12px;
}
#adlts{
  font-size: 18px;
  margin-top: 6px;
  padding: 0px 40px;
  border: 1px solid #D3D3D3;
}
#rmvicn,
#adicn{
  font-size: 28px;
  border: 1px solid #D3D3D3;
}